import {
  Button,
  Col,
  Container,
  Form,
  FormCheck,
  Modal,
  ModalBody,
  ModalTitle,
  Row
} from "react-bootstrap";
import Snowfall from 'react-snowfall'

import {
  useState,
  useEffect,
  Component
} from "react";

import { useNavigate, useParams, useHref } from "react-router";
import { AmplifySignOut } from '@aws-amplify/ui-react';

import 'bootstrap/dist/css/bootstrap.min.css';

import './App.css';
import ModalHeader from "react-bootstrap/esm/ModalHeader";

import {
  getGroup,
  addParticipant,
  toggleGetting,
  createWish,
  deleteWish
} from './Api.js';
import { Auth } from "aws-amplify";
import { Create, CreateOutline, Link, TrashBin, Gift, LockClosed } from 'react-ionicons';

class SnowOverlay extends Component {

  constructor(props) {
    super(props);

    this.state = {
      top: 0,
    }
  }

  componentDidMount() {
    this.scrollListener = window.addEventListener('scroll', (e) => {
      this.setState({
        top: window.scrollY,
      })
    });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollListener);
  }

  render() {
    return <Snowfall style={{
      position: 'absolute',
      top: this.state.top
    }} />;
  }
}

const NiceGoal = {
  giftsForOthers: 'giftsForOthers',
  wishesForSelf: 'wishesForSelf',
  suggestionsForOthers: 'suggestionsForOthers'
}

function Group() {

  const navigate = useNavigate();
  const [title, setTitle] = useState("")
  const [ownerId, setOwnerId] = useState("")
  const [addMember, setAddMember] = useState(false)
  const [username, setUsername] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const [members, setMembers] = useState([])
  const [wishes, setWishes] = useState([])
  const [activeUserId, setActiveUserId] = useState();
  const [niceGoal, setNiceGoal] = useState(NiceGoal.giftsForOthers);
  const [addWish, setAddWish] = useState(false);
  const [wishTitle, setWishTitle] = useState('');
  const [wishUrl, setWishUrl] = useState('');
  const [selectedUserId, setSelectedUserId] = useState();
  const [isDeleting, setIsDeleting] = useState(false);
  const [deletionTarget, setDeletionTarget] = useState();
  const [deletionTitle, setDeletionTitle] = useState();

  let search = window.location.search;
  let params = new URLSearchParams(search);

  useEffect(() => {
    Auth.currentUserInfo().then(user => {
      setActiveUserId(user.username)
    })
    getGroup(params.get("id")).then(response => {

      console.log("response", response)
      console.log(response)
      setTitle(response.GroupName)
      setOwnerId(response.OwnerId)
      setMembers(response.Members || [])
      setWishes([...response.Wishes, ...response.Suggestions])
    })
  }, [])

  const submitAddMember = () => {
    addParticipant(params.get("id"), username).then(response => {
      if (!response.success) {
        setErrorMessage(response.message)
      } else {
        setMembers([...members, { Username: username }])
        setAddMember(false)
      }
    })
  }

  const submitDelete = () => {
    // TODO
    deleteWish(selectedUserId, deletionTarget).then(response => {
      let newWishes = wishes.filter(wish => wish.WishId !== deletionTarget && wish.SuggestionId !== deletionTarget)
      setWishes(newWishes)
      setIsDeleting(false)
      setDeletionTarget(undefined)
      setDeletionTitle(undefined)
      setSelectedUserId()
    })
  }

  const cancelDelete = () => {
    setIsDeleting(false)
    setDeletionTarget(undefined)
    setDeletionTitle(undefined)
    setSelectedUserId()
  }

  const submitAddWish = () => {
    const valid = wishUrl.startsWith('https://') || wishUrl.startsWith('http://') || wishUrl.length === 0
    if (valid) {
      createWish(selectedUserId, wishTitle, wishUrl, '', params.get('id')).then(response => {
        if (response.success) {
          setWishes([...wishes, {
            Title: wishTitle,
            WishId: response.wishId,
            WishUrl: wishUrl,
            WisherId: selectedUserId === activeUserId ? activeUserId : undefined,
            RecommenderId: selectedUserId === activeUserId ? undefined : activeUserId,
            RecommendeeId: selectedUserId === activeUserId ? undefined : selectedUserId,
            GetterId: undefined
          }])
          setWishTitle("")
          setWishUrl("")
          setAddWish(false)
        }
      })
    } else {
      alert("Please start your url with http:// or https://")
    }
  }

  const updateGoal = (newGoal) => {
    setNiceGoal(newGoal);
    switch (newGoal) {
      case NiceGoal.giftsForOthers:
        break;
      case NiceGoal.suggestionsForOthers:
        break;
      case NiceGoal.wishesForSelf:
        break;
      default:
        // Shouldn't enter
        break;
    }
  }

  const Status = () => {

    // Gifts for others
    let presentsForOthers = wishes
      .filter(wish => wish.GetterId === activeUserId)
      .map(wish => wish.WisherId || wish.RecommendeeId);
    let uniqueOthers = presentsForOthers.filter((item, index) => {
      return presentsForOthers.indexOf(item) === index;
    })
    let giftsForOthersGood = uniqueOthers.length >= 7;

    // Wishes for self
    let wishesForSelf = wishes.filter(wish => wish.WisherId === activeUserId);
    let wishesForSelfGood = wishesForSelf.length >= 4;

    // Recommendations for others
    let recommendationsForOthers = wishes.filter(wish => wish.RecommenderId === activeUserId);
    let recommendationsGood = recommendationsForOthers.length >= 4;

    // Nice
    let isNice = giftsForOthersGood && wishesForSelfGood && recommendationsGood;

    let selectedBorder = "" //  "solid black 1px"

    return <div className={isNice ? "HorizontalCell" : "SecondaryHorizontalCell"}>
      <div>Your status: {isNice ? "NICE" : "NAUGHTY"}</div>
      <div onClick={() => updateGoal(NiceGoal.giftsForOthers)} style={{
        border: niceGoal === NiceGoal.giftsForOthers ? selectedBorder : ""
      }} className={giftsForOthersGood ? "SectionBody" : "SecondarySectionBody"}>
        Presents for others: {uniqueOthers.length} / 7
      </div>
      <div onClick={() => updateGoal(NiceGoal.wishesForSelf)} style={{
        border: niceGoal === NiceGoal.wishesForSelf ? selectedBorder : ""
      }} className={wishesForSelfGood ? "SectionBody" : "SecondarySectionBody"}>
        Your wishes for yourself: {wishesForSelf.length} / 4
      </div>
      <div onClick={() => updateGoal(NiceGoal.suggestionsForOthers)} style={{
        border: niceGoal === NiceGoal.suggestionsForOthers ? selectedBorder : ""
      }} className={recommendationsGood ? "SectionBody" : "SecondarySectionBody"}>
        Your suggestions for others: {recommendationsForOthers.length} / 4
      </div>
    </div>
  }

  const Wish = ({ title, wishId, suggestionId, wisherId, recommenderId, url, getterId, recommendeeId }) => {
    return <div className="SectionBody" style={{
      width: 150, 
      flexShrink: 0,
      marginRight: 10,
      marginTop: 10,
      position: 'relative'
    }}>
      {
        wisherId==null &&
      <div style={{
        backgroundColor: '#eacaff',
        borderRadius: '8pt',
        paddingLeft: '3pt',
        paddingRight: '3pt',
        alignContent: 'center',
        alignItems: 'center',
        width: 100,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        height: '16pt'
      }}>
        <div style={{flexDirection: 'column', justifyContent: 'center'}}>
        <LockClosed color={'#8100d7'} height={'12pt'} width={'12pt'} />
        </div>
        <span style={{color: '#8100d7', fontSize: '12pt'}}>Suggestion</span>
      </div>
      }
      <div>{title}</div>
      {
        (wisherId !== activeUserId) ?
          <div
            className="wish-check"
            onClick={(e) => {
               if (getterId == null) {
                 toggleGetting(wishId, suggestionId).then(() => {
                   let targetWishIndex = wishes.findIndex(thisWish => thisWish.WishId === wishId && thisWish.SuggestionId === suggestionId)
                   let newWishes = JSON.parse(JSON.stringify(wishes)) // Deep copy
                   newWishes[targetWishIndex].GetterId = activeUserId
                   console.log(targetWishIndex, wishes, newWishes);
                   setWishes(newWishes)
                 })
               } else if (getterId === activeUserId) {
                 toggleGetting(wishId, suggestionId).then(() => {
                   let targetWishIndex = wishes.findIndex(thisWish => thisWish.WishId === wishId && thisWish.SuggestionId === suggestionId)
                   let newWishes = JSON.parse(JSON.stringify(wishes)) // Deep copy
                   newWishes[targetWishIndex].GetterId = null
                   console.log(targetWishIndex, wishes, newWishes);
                   setWishes(newWishes)
                 })
               }
            }}
          >
            {getterId == null ? <Gift color={'red'} size={10} /> : getterId.substring(0,2)}
          </div>
           // <FormCheck
           //   disabled={getterId != null && getterId !== activeUserId}
           //   type='checkbox'
           //   inline
           //   checked={getterId != null}
           //   onChange={() => {
           //     if (getterId == null) {
           //       toggleGetting(wishId, suggestionId).then(() => {
           //         let targetWishIndex = wishes.findIndex(thisWish => thisWish.WishId === wishId && thisWish.SuggestionId === suggestionId)
           //         let newWishes = JSON.parse(JSON.stringify(wishes)) // Deep copy
           //         newWishes[targetWishIndex].GetterId = activeUserId
           //         console.log(targetWishIndex, wishes, newWishes);
           //         setWishes(newWishes)
           //       })
           //     } else {
           //       toggleGetting(wishId, suggestionId).then(() => {
           //         let targetWishIndex = wishes.findIndex(thisWish => thisWish.WishId === wishId && thisWish.SuggestionId === suggestionId)
           //         let newWishes = JSON.parse(JSON.stringify(wishes)) // Deep copy
           //         newWishes[targetWishIndex].GetterId = null
           //         console.log(targetWishIndex, wishes, newWishes);
           //         setWishes(newWishes)
           //       })
           //     }
           //   }}
           //   label={`${(getterId == null) ? ("Nobody is") : ((getterId === activeUserId) ? "You are" : getterId + " is")} getting ${title} for ${wisherId || recommendeeId}`}
           // />
          :
          <></>
      }
      {
        <div>
          {
            wisherId === activeUserId || recommenderId === activeUserId ?
              <TrashBin color={"red"} size={20} onClick={() => {
                setSelectedUserId(wisherId || recommendeeId)
                setIsDeleting(true)
                setDeletionTarget(wishId || suggestionId)
                setDeletionTitle(title)
              }} />
              :
              <></>
          }
          {
            url ?
              <Link color={"white"} size={20} onClick={() => {
                window.open(url)
              }} />
              :
              <></>
          }
        </div>
      }
    </div>;
  }

  const Member = ({ member }) => {

    let thisWishes = wishes
      .filter(wish =>
        (wish.WisherId != null && member.Username != null && wish.WisherId.toLowerCase() === member.Username.toLowerCase()) ||
        (wish.RecommendeeId != null && member.Username != null && wish.RecommendeeId === member.Username))
      .filter(wish =>
        wish.RecommendeeId !== activeUserId);

    return (
      <div
        className="HorizontalCell"
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span>{member.Username}</span>
          <CreateOutline onClick={() => {
            setAddWish(true);
            setSelectedUserId(member.Username)
          }} color={"white"} />
        </div>
        <div className="hscroll">
          {thisWishes.map((wish, idx) => <Wish
            key={idx}
            title={wish.Title}
            wishId={wish.WishId}
            suggestionId={wish.SuggestionId}
            recommenderId={wish.RecommenderId}
            recommendeeId={wish.RecommendeeId}
            wisherId={wish.WisherId}
            url={wish.WishUrl || wish.SuggestionUrl}
            getterId={wish.GetterId}
          />)}
        </div>
      </div>
    )
  }

  return (
    <>
      <SnowOverlay />
      <Container>
        <Row className="TitleBar">
          <Col>
            <Button onClick={() => {
              navigate('../')
            }}>
              Home
            </Button>
          </Col>
          <Col>
            {title}
          </Col>
          <Col>
            <AmplifySignOut />
          </Col>
        </Row>
        {
          members.filter(member => member.Username === activeUserId).length > 0 ?
            <>
              <Row className="Section">
                <Col md={8} lg={6}>
                  <Button className="shadow-none" onClick={() => {
                    setAddMember(true)
                  }}>
                    Add Member
                  </Button>
                </Col>
              </Row>
              <Row className="Section">
                <Col md={8} lg={6}>
                  <Status />
                  {members.map((thisMember, idx) => <Member key={idx} member={thisMember} />)}
                </Col>
              </Row>
            </>
            :
            <Row className="Section">
              <Col md={8} lg={6}>
                <Button className="shadow-none" onClick={() => {
                  addParticipant(params.get("id"), activeUserId).then(response => {
                    console.log(response)
                    if (!response.success) {
                      setErrorMessage(response.message)
                    } else {
                      setMembers([...members, { Username: activeUserId }])
                    }
                  })
                }}>
                  Join Wishlist
                </Button>
              </Col>
            </Row>
        }
      </Container>
      <Modal
        show={addWish}
        onHide={() => {
          setAddWish(false)
          setWishTitle("")
          setWishUrl("")
          setSelectedUserId()
        }}
      >
        <ModalHeader>
          <ModalTitle>
            Add a {(activeUserId === selectedUserId) ? 'Wish' : 'Suggestion'}
          </ModalTitle>
        </ModalHeader>
        <ModalBody>
          <Form.Group>
            <Form.Label>{(activeUserId === selectedUserId) ? 'Wish' : 'Suggestion'} Title</Form.Label>
            <Form.Control placeholder="Red firetruck" onChange={e => setWishTitle(e.target.value)} />
          </Form.Group>
          <br />
          <Form.Group>
            <Form.Label>{(activeUserId === selectedUserId) ? 'Wish' : 'Suggestion'} URL</Form.Label>
            <Form.Control placeholder="https://www.amazon.com/s?k=red+firetruck" onChange={e => setWishUrl(e.target.value)} />
          </Form.Group>
          <br />
          <Button className="shadow-none" onClick={submitAddWish}>
            Submit
          </Button>
        </ModalBody>
      </Modal>
      <Modal
        show={addMember}
        onHide={() => {
          setAddMember(false)
          setUsername("")
          setErrorMessage("")
        }}
      >
        <ModalHeader>
          <ModalTitle>
            Add a Member
          </ModalTitle>
        </ModalHeader>
        <ModalBody>
          <Form.Group>
            <Form.Label>Username</Form.Label>
            <Form.Control placeholder="Enter the member's username" onChange={e => {
              setErrorMessage("")
              setUsername(e.target.value)
            }} />
            <Form.Text className="text-muted">{errorMessage}</Form.Text>
          </Form.Group>
          <br />
          <Button className="shadow-none" onClick={submitAddMember}>
            Submit
          </Button>
        </ModalBody>
      </Modal>
      <Modal
        show={isDeleting}
        onHide={() => {
          setIsDeleting(false)
          setDeletionTarget(undefined)
          setDeletionTitle(undefined)
        }}
      >
        <ModalHeader>
          <ModalTitle>
            Delete This {(activeUserId === selectedUserId) ? 'Wish' : 'Suggestion'}
          </ModalTitle>
        </ModalHeader>
        <ModalBody>
          <div>
            Are you sure you want to delete {deletionTitle}?
          </div>
          <div>
            This action cannot be undone
          </div>
          <br />
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}>
            <Button className="shadow-none" onClick={submitDelete}>
              Yes
            </Button>
            <Button className="shadow-none" onClick={cancelDelete}>
              No
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}

export default Group;